import React, { Component } from 'react'

/* libraries */
import debounce from 'lodash/debounce'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

/* styles */
const Container = styled('div')`
  -webkit-overflow-scrolling: touch;
  position: relative;
  height: 100%;
  box-sizing: border-box;
`

const Gallery = styled('div')`
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
  scroll-snap-destination: 0% 100%;
  scroll-snap-points-x: repeat(100%);
  -webkit-overflow-scrolling: touch;
  display: flex;
  scroll-snap-type: x mandatory;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden; /* disable scroll bars */
  @supports (-moz-appearance:meterbar) {
    scroll-snap-type: mandatory;
  }
`

const Slide = styled('div')`
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  > div {
    text-align: center;
    width: 100%;
  }
`

const sliderButton = `
  position: absolute;
  width: 20%;
  height: 100%;
  top: 0;
  bottom: 0;
  cursor: pointer;
  border: none;
  background: transparent;
  background-repeat: no-repeat;
  pointer-events: all;
  outline: none !important;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-size: 11px 19px;
  transition: background-position 0.25s ease-in-out;
  z-index: 6;

  &:focus {
    // background-color: rgba(255, 255, 255, 0.1);
  }

  &:before {
    font-size: 36px;
    color: black;
    font-family: 'Monument','Helvetica Neue',Helvetica;;
  }
`

const bounceLeft = keyframes`
  0%, 20%, 40% { left: 0px; }
  10%, 30% { left: 10px; }
`
const bounceRight = keyframes`
  0%, 20%, 40% { right: 0px; }
  10%, 30% { right: 10px; }
`

// background-image: url(${larr});
const Prev = styled('button')`
  ${sliderButton}
  left: 0;
  border-radius: 0 50% 50% 0;
  background-position: top 50% left -35px;
  animation: ${bounceLeft} 1.5s ease-in-out 3s forwards;

  &:before {
    content: "←";
    padding-right: 50%;
  }

  &:hover {
    background-position: top 50% left 35px;
  }
`

const Next = styled('button')`
  ${sliderButton}
  right: 0;
  border-radius: 50% 0 0 50%;
  background-position: top 50% right -35px;
  animation: ${bounceRight} 1.5s ease-in-out 3s forwards;

  &:before {
    content: "→";
    padding-left: 50%;
  }

  &:hover {
    background-position: top 50% right 35px;
  }
`

const Bullets = styled('nav')`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 6;
  bottom: 15px;
  color: black;
  font-size: 15px;
`

// eslint-disable-next-line no-unused-vars
const Bullet = styled('button')`
  background: transparent;
  border-width: 0;
  position: relative;
  height: 16px;
  width: 12px;
  opacity: 0.5;
  flex: 1;
  margin: 1px;
  transition: opacity 0.1s ease-in-out;
  cursor: pointer;

  &:before {
    content: " ";
    position: absolute;
    left: 3px;
    top: 5px;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: white;
  }

  ${({ active }) => active && `opacity: 1`}
`

class Slider extends Component {
  constructor (props) {
    super(props)
    this.state = { current: 0 }
    this.debouncedScroll = debounce(this.scrolled, 50)
  }

  componentDidMount () {
    this.gallery.scrollTo({ left: 0 })
    this.gallery.addEventListener('scroll', this.debouncedScroll)
    this.gallery.addEventListener('resize', this.onResize)
    this.onResize()
    this.setState({
      total: this.props.children.length
    })
  }

  componentWillUnmount () {
    this.gallery.removeEventListener('scroll', this.debouncedScroll)
    this.gallery.removeEventListener('resize', this.onResize)
  }

  onResize = () => {
    if (!this.gallery) return
    this.setState({
      width: this.gallery.getBoundingClientRect().width
    })
  }

  scrolled = (_, destination) => {
    if (!this.gallery) return
    const { width } = this.state
    const current = destination
      ? Math.floor(destination / width)
      : Math.floor((this.gallery.scrollLeft + width / 2) / width)
    this.setState({
      current
    })
  }

  next = () => {
    if (!this.gallery) return
    const { width } = this.state
    if (this.state.current + 1 < this.state.total) {
      this.gallery.scrollBy({
        left: width,
        behavior: 'smooth'
      })
      this.scrolled(0, this.gallery.scrollLeft + width)
    } else {
      this.gallery.scrollTo({ left: 0, behavior: 'smooth' })
      this.scrolled(0, 0)
    }
  }

  prev = () => {
    if (!this.gallery) return
    const { width, total } = this.state
    if (this.state.current === 0) {
      this.gallery.scrollBy({
        left: (total - 1) * width,
        behavior: 'smooth'
      })
      this.scrolled(0, (total - 1) * width)
    } else {
      this.gallery.scrollBy({
        left: -width,
        behavior: 'smooth'
      })
      this.scrolled(0, this.gallery.scrollLeft - width)
    }
  }

  goToSlide = n => {
    if (!this.gallery) return
    const { width } = this.state
    const delta = n - this.state.current
    this.gallery.scrollBy({
      left: width * delta,
      behavior: 'smooth'
    })
    this.scrolled(0, this.gallery.scrollLeft + width * delta)
  }

  render () {
    const { children } = this.props
    const { current, total } = this.state

    return (
      <Container aria-label='gallery'>
        <Gallery
          ref={ref => {
            this.gallery = ref
          }}
        >
          {children.map((child, i) => (
            <Slide key={`slide-${i}`}>{child}</Slide>
          ))}
        </Gallery>
        {total > 0 && (
          <React.Fragment>
            <Prev aria-label='previous Slide' onClick={this.prev} />
            <Next aria-label='next Slide' onClick={this.next} />
            <Bullets role='navigation'>
              <span>
                Slide {current + 1} of {total}
              </span>
            </Bullets>
          </React.Fragment>
        )}
      </Container>
    )
  }
}

export default Slider
