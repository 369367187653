import React from 'react'
import styled from '@emotion/styled'
import { CartContext } from './App'
import Modal from './Modal'
import products from './products.json'
import { FONTSIZE, BORDER, BORDERWIDTH, BLUE, DARKBLUE, GREY } from './utils'
import cards from './cards.png'

const PageTitle = styled('h2')`
  padding: 16px;
  font-size: ${FONTSIZE};
  text-transform: uppercase;
  border: ${BORDER};
  border-right-width: 0;
  border-left-width: 0;
  text-align: center;
  margin: 0;
`

const Container = styled('div')`
  display: flex;
  flex-direction: row;

  @media (max-width: 480px) {
    flex-direction: column;
    margin-left: ${`-${BORDERWIDTH}px`};
  }
`

const ProductsList = styled('div')`
  flex: 2;
  display: flex;
  flex-direction: column;
`

const Product = styled('div')`
  display: flex;
  flex-direction: row;
  border-bottom: ${BORDER};
  flex-grow: 0;
  &:last-of-type {
    flex-grow: 1;
    @media (min-width: 481px) {
      margin-bottom: ${`-${BORDERWIDTH}px`};
      border-bottom-width: 0;
    }
    > section {
      padding: 0 16px;
      @media (max-width: 480px) {
        padding: 48px 16px;
      }
    }
  }
`

const CheckoutInfo = styled('div')`
  flex: 1;
  border-left: ${BORDER};
  font-size: ${FONTSIZE};
  display: flex;
  flex-direction: column;

  > div {
    padding: 16px;
    border-bottom: ${BORDER};
    flex-grow: 0;
    &:last-of-type {
      border-bottom-width: 0;
      flex-grow: 1;
    }
  }
`

const Empty = styled('div')`
  flex-basis: 0;
  flex-grow: 1;
  border-right: ${BORDER};
`

const SmallImage = styled('figure')`
  width: 200px;
  margin: 0;
  border-right: ${BORDER};
  flex-basis: 1;

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
`

const ProductInfo = styled('section')`
  flex-basis: 500px;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
`

const ProductText = styled('h3')`
  text-transform: uppercase;
  flex: calc(100% - 40px);
  font-size: ${FONTSIZE};
  margin: 0;
`

// eslint-disable-next-line no-unused-vars
const Amount = styled('div')`
  flex: 70%;
  font-size: ${FONTSIZE};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
`

// eslint-disable-next-line no-unused-vars
const Price = styled('div')`
  flex: 30%;
  font-size: ${FONTSIZE};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`

const RemoveFromCartButton = styled('button')`
  background-color: transparent;
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E %3Cg fill='none' fill-rule='nonzero' stroke='%23000' stroke-linecap='square' stroke-width='1.5'%3E %3Cpath d='M1.4 1l13 12.8M14.2 1l-13 12.8'/%3E %3C/g%3E %3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  flex: 40px;
  height: 40px;
  margin: -10px -10px 10px 10px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`

const Flex = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const Left = styled('span')`
  text-transform: uppercase;
`
const Right = styled('span')`
  text-align: right;
`
const CheckOutButton = styled('button')`
  border: none;
  border-top: ${BORDER};
  text-transform: uppercase;
  background-color: ${BLUE};
  font-size: ${FONTSIZE};
  width: 100%;
  appearance: none;
  user-select: none;
  text-align: center;
  padding: 16px;
  cursor: pointer;
  transition: background-color 100ms ease-in-out;
  &:enabled:hover {
    background-color: ${DARKBLUE};
  }
  &:disabled {
    background-color: ${GREY};
    cursor: default;
  }
  @media (min-width: 481px) {
    border-bottom: ${BORDER};
  }
`

export default props => {
  const [modal, setModal] = React.useState(false)
  const { cartItems: cartItemIds, removeFromCart } = React.useContext(
    CartContext
  )
  const cartItems = products.filter(d => cartItemIds.includes(d.id))
  const subtotal = cartItems.reduce((sum, product) => sum + product.price, 0)

  const constructMail = () => {
    const head = `Dear New Office,%0D%0A
%0D%0A
I would like to purchase the following items:%0D%0A
%0D%0A`
    const tail = `%0D%0A%0D%0A
Please send it to the following address:%0D%0A
%0D%0A
%0D%0A`
    return (
      head +
      cartItems
        .map(
          product => `
${product.name}%0D%0A
"${product.subtitle}"%0D%0A
PRICE: EUR ${product.price}%0D%0A
AMOUNT: 1
`
        )
        .join('%0D%0A---%0D%0A') +
      tail
    )
  }
  return (
    <React.Fragment>
      <PageTitle>Shopping Bag</PageTitle>
      <Container>
        <ProductsList>
          {cartItems.map(product => (
            <Product key={`cart-product-${product.id}`}>
              <Empty />
              <SmallImage>
                <img
                  src={`${process.env.PUBLIC_URL}/images/home/${
                    product.id
                  }_01.jpg`}
                  alt=''
                />
              </SmallImage>
              <ProductInfo>
                <ProductText>
                  {product.name}
                  <br />"{product.subtitle}"
                  <br />
                  EUR {product.price}
                </ProductText>
                <RemoveFromCartButton
                  onClick={() => {
                    removeFromCart(product.id)
                  }}
                />
                {/* <Amount>{product.subtitle}</Amount>
                <Price>
                  <span>EUR {product.price}</span>
                </Price> */}
              </ProductInfo>
            </Product>
          ))}
          <Product key={`cart-product-empty`}>
            <Empty />
            <SmallImage />
            <ProductInfo
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {cartItemIds.length < 1 && <span>Shopping Bag is empty</span>}
            </ProductInfo>
          </Product>
        </ProductsList>
        <CheckoutInfo>
          <div>
            Do you have any questions? Get in touch: <br />
            <br />
            <a href='mailto:info@newoffice.store'>info@newoffice.store</a>
          </div>
          <div>
            SECURE PAYMENT
            <br />
            <br />
            Credit Card
            <br />
            Paypal
            <br />
            <br />
            The payment information is transmitted with the highest security
            standard.
            <br />
            <br />
            <br />
            <img
              src={cards}
              alt=''
              style={{
                maxWidth: '80%',
                width: 215,
                height: 'auto'
              }}
            />
          </div>
          <div>
            <Flex>
              <Left>Subtotal</Left>
              <Right>EUR {subtotal}</Right>
            </Flex>
          </div>
          <div>
            <Flex>
              <Left>Shipping</Left>
              <Right>EUR {7}</Right>
            </Flex>
          </div>
          <div>
            <Flex>
              <Left>Total</Left>
              <Right>EUR {subtotal + 7}</Right>
            </Flex>
          </div>
        </CheckoutInfo>
      </Container>
      <CheckOutButton
        disabled={cartItemIds.length < 1}
        onClick={() => {
          setModal(true)
        }}
      >
        Check out
      </CheckOutButton>

      {modal && (
        <Modal
          onCloseButtonClick={() => {
            setModal(false)
          }}
        >
          <p>
            We are currently taking pre-orders. Let us know which items you are
            interested in and we will ship it to you as soon as possible:
            <br />
            <br />
            <a
              href={`mailto:order@newoffice.store?subject=Order New Office&body=${constructMail()}`}
            >
              Transfer cart via Mail to
              <br />
              order@newoffice.store
            </a>
          </p>
        </Modal>
      )}
    </React.Fragment>
  )
}
