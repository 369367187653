import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { FONTSIZE, BORDER, BORDERWIDTH } from './utils'

const Container = styled('main')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const Item = styled('div')`
  flex-basis: 25%;
  flex-grow: 0;
  border: ${BORDER};
  border-left-width: 0;
  margin-top: ${`-${BORDERWIDTH}px`};
  padding: 0;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 1024px) {
    flex-basis: 33.3%;
    flex-grow: 0;
  }
  @media (max-width: 720px) {
    flex-basis: 50%;
    flex-grow: 0;
  }
  @media (max-width: 480px) {
    flex-basis: 100%;
    flex-grow: 0;
  }
  &:last-of-type {
    flex-grow: 0;
    @media (max-width: 480px) {
      border-bottom-width: 0;
    }
  }

  @media (max-width: 480px) {
    border-right-width: 0;
  }
`

export const ProductImage = styled('img')`
  max-width: 100%;
  height: auto;
  
  ${({ alternative }) =>
    alternative &&
    `
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: -1;
  `}
  ${({ hideOnHover }) =>
    hideOnHover &&
    `
    &:hover {
      @media (min-width: 481px) {
        opacity: 0;
      }
    }
  `}
`

export const ProductText = styled('h4')`
  font-size: ${FONTSIZE};
  line-height: 1.1;
  font-weight: normal;
  margin: 16px;
  padding: 0;
`

export const BackToTop = styled('button')`
  background: transparent;
  border: none;
  text-transform: uppercase;
  font-size: ${FONTSIZE};
  width: 100%;
  appearance: none;
  user-select: none;
  text-align: center;
  padding: 16px;
  cursor: pointer;
  margin: 0;
  outline: 0 !important;
  border-top: ${BORDER};

  @media (min-width: 481px) {
    border-bottom: ${BORDER};
  }
`

export default ({ products }) => {
  return (
    <Container>
      {products.map(product => (
        <Item key={`product-${product.id}`}>
          <Link to={`/product/${product.id}`}>
            <ProductImage
              hideOnHover
              src={`${process.env.PUBLIC_URL}/images/home/${product.id}_01.jpg`}
            />
            <ProductImage
              alternative
              src={`${process.env.PUBLIC_URL}/images/home/${product.id}_02.jpg`}
            />
            <ProductText>
              {product.name}
              <br />
              "{product.subtitle}"
              <br />
              EUR {product.price}
            </ProductText>
          </Link>
        </Item>
      ))}
      <BackToTop
        style={{ marginTop: -1 }}
        onClick={() => {
          window.scrollTo({
            behavior: 'smooth',
            top: 0
          })
        }}
      >
        Back to top
      </BackToTop>
    </Container>
  )
}
