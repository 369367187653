import React from 'react'
import styled from '@emotion/styled'
import { BORDER, FONTSIZE } from './utils'

import about320 from './about_320.jpg'
import about640 from './about_640.jpg'
import about960 from './about_960.jpg'
import about1280 from './about_1280.jpg'
import about1680 from './about_1680.jpg'

export const Container = styled('article')`
  border-top: ${BORDER};
  padding-bottom: 48px;
  @media (min-width: 481px) {
    padding-top: 24px;
    padding-bottom: 96px;
  }
`

export const Paragraph = styled('p')`
  line-height: 1.25;
  text-align: center;
  margin: 12px auto;
  padding: 0 12px;
  font-size: 18px;
  @media (min-width: 481px) {
    margin: 24px auto;
    padding: 0 24px;
    font-size: 24px;
    max-width: 1200px;
  }
  @media (min-width: 1600px) {
    max-width: 1460px;
  }
`

export const Divider = styled('h3')`
  text-transform: uppercase;
  border: ${BORDER};
  border-right-width: 0;
  border-left-width: 0;
  text-align: center;
  font-size: ${FONTSIZE};
  padding: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
  @media (min-width: 481px) {
    margin-top: 48px;
    margin-bottom: 32px;
  }
`
// eslint-disable-next-line no-unused-vars
const AimList = styled('ul')`
  padding-left: 0;
  list-style-type: none;

  @media (min-width: 481px) {
    padding-top: 48px;
  }
  li {
    text-align: center;
    margin: 6px 12px;
    text-transform: uppercase;
  }
`

const ImageContainer = styled('figure')`
  margin: 24px;
  height: 720px;
  text-align: center;

  @media (min-width: 481px) {
    margin: 48px;
  }

  img {
    @media (max-width: 480px) {
      max-width: 100%;
      height: auto;
    }
    @media (min-width: 481px) {
      max-height: 100%;
      width: auto;
    }
  }
`

export default props => (
  <Container>
    <Paragraph>
      The humorous project NEW OFFICE aims to improve conditions for freelancers
      working in a semi-public space such as a café. A series of tools include
      different kinds of workwear, alongside false backdrops for Skype meetings,
      pointing out the challenges faced by freelancers trying to maintain
      professionalism while in a transient context.
    </Paragraph>
    <Paragraph>
      Freelancing is a form of work whereby workers offer hourly rates to
      various clients. Recently this type of work has gained popularity in the
      creative industry. Workers can choose to work at home, at a fixed table
      within a co-working space or at a cafe. People sitting with laptops in
      front of them at cafe tables has become a familiar sight. How can we
      distinguish who earns a living and who is there for pleasure?
    </Paragraph>
    <Paragraph>
      NEW OFFICE serves freelancers in particular, assisting them in
      communicating that they hold the same level of importance as people
      working in formal office environments. It helps them claim space within
      public environments which are not primarily intended for work.
    </Paragraph>
    <Paragraph>
      Get in touch:{' '}
      <a href='mailto:info@newoffice.store'>info@newoffice.store</a>
    </Paragraph>
    <Divider>Stay flexible, be productive</Divider>
    <ImageContainer>
      <img
        sizes='(max-width: 1680px) 100vw, 1680px'
        srcset={`
        ${about320} 320w,
        ${about640} 640w,
        ${about960} 960w,
        ${about1280} 1280w,
        ${about1680} 1680w,
      `}
        src={about1680}
        alt=''
      />
    </ImageContainer>
  </Container>
)
