import React from 'react'
import ReactDOM from 'react-dom'
import styled from '@emotion/styled'
import { BORDER } from './utils'

const modalRoot = document.getElementById('modal')

const SemiTransparent = styled('div')`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
`

const Message = styled('div')`
  width: 60%;
  margin: 0 12px;
  max-width: 800px;
  min-height: 400px;
  background-color: #F5F5F5;
  border: ${BORDER};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px 40px;
  
  @media (min-width: 481px) {
    padding: 40px 80px;
  }
`

const Content = styled('div')`
  line-height: 1.25;
  text-align: center;
  font-size: 20px;

  @media (min-width: 481px) {
    font-size: 28px;
  }
`

const CloseButton = styled('button')`
  position: absolute;
  top: 6px;
  right: 6px;
  background-color: transparent;
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E %3Cg fill='none' fill-rule='nonzero' stroke='%23000' stroke-linecap='square' stroke-width='1.5'%3E %3Cpath d='M1.4 1l13 12.8M14.2 1l-13 12.8'/%3E %3C/g%3E %3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  width: 60px;
  height: 60px;
  border: none;
  cursor: pointer;
`

class Modal extends React.Component {
  constructor (props) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount () {
    modalRoot.appendChild(this.el)
    document.body.style.overflow = 'hidden'
  }

  componentWillUnmount () {
    modalRoot.removeChild(this.el)
    document.body.style.overflow = 'auto'
  }

  render () {
    return ReactDOM.createPortal(
      <React.Fragment>
        <SemiTransparent>
          <Message>
            <Content>{this.props.children}</Content>
            <CloseButton onClick={this.props.onCloseButtonClick} />
          </Message>
        </SemiTransparent>
      </React.Fragment>,
      this.el
    )
  }
}

export default Modal
