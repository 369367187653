/** @jsx jsx */
/* eslint-disable no-unused-vars */
import React from 'react'

import styled from '@emotion/styled'
import { css, jsx } from '@emotion/core'
import { range, pad } from './utils'
import { BackToTop } from './Shop'

const GridContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  div {
    background-position: center;
    background-size: cover;
    padding-top: calc(4 / 3 * 100%);
  }
`

const Divider = styled('aside')`
  background-color: #b7b7b7;
  padding: 8px;
  display: flex;
  justify-content: space-evenly;
  overflow: hidden;
  font-family: 'Adieu';
  font-size: 16px;

  > span {
    flex-grow: 1;
    flex-shrink: 0;
    color: red;
    text-transform: uppercase;
    padding: 8px;
    text-align: center;
  }
`

const first = css`
  grid-column: 1 / 5;
  grid-row: 3 / 4;

  @media (max-width: 480px) {
    grid-column: 1 / 5;
    grid-row: 4 / 5;
  }
`
const second = css`
  grid-column: 1 / 5;
  grid-row: 8 / 9;

  @media (max-width: 480px) {
    grid-column: 1 / 5;
    grid-row: 9 / 10;
  }
`
/* new row */
const img01 = css`
  grid-column: 1 / 3;
  grid-row: 1 / 3;

  @media (max-width: 480px) {
    grid-column: 1 / 5;
    grid-row: 1 / 2;
  }
`
const img02 = css`
  grid-column: 3 / 4;
  grid-row: 1 / 2;

  @media (max-width: 480px) {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
  }
`
const img03 = css`
  grid-column: 4 / 5;
  grid-row: 1 / 2;

  @media (max-width: 480px) {
    grid-column: 3 / 5;
    grid-row: 2 / 3;
  }
`
const img04 = css`
  grid-column: 3 / 4;
  grid-row: 2 / 3;

  @media (max-width: 480px) {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
  }
`
const img05 = css`
  grid-column: 4 / 5;
  grid-row: 2 / 3;

  @media (max-width: 480px) {
    grid-column: 3 / 5;
    grid-row: 3 / 4;
  }
`
/* new row */
/* special row */
/* new row */
const img09 = css`
  grid-column: 1 / 2;
  grid-row: 6 / 7;

  @media (max-width: 480px) {
    grid-column: 1 / 3;
    grid-row: 6 / 7;
  }
`
const img10 = css`
  grid-column: 2 / 3;
  grid-row: 6 / 7;

  @media (max-width: 480px) {
    grid-column: 3 / 5;
    grid-row: 6 / 7;
  }
`
const img11 = css`
  grid-column: 1 / 2;
  grid-row: 7 / 8;

  @media (max-width: 480px) {
    grid-column: 1 / 3;
    grid-row: 8 / 9;
  }
`
const img12 = css`
  grid-column: 2 / 3;
  grid-row: 7 / 8;

  @media (max-width: 480px) {
    grid-column: 3 / 5;
    grid-row: 8 / 9;
  }
`
const img13 = css`
  grid-column: 3 / 5;
  grid-row: 6 / 8;

  @media (max-width: 480px) {
    grid-column: 1 / 5;
    grid-row: 7 / 8;
  }
`
/* new row */
const img14 = css`
  grid-column: 1 / 3;
  grid-row: 9 / 10;

  @media (max-width: 480px) {
    grid-column: 1 / 3;
    grid-row: 10 / 11;
  }
`
const img15 = css`
  grid-column: 3 / 5;
  grid-row: 9 / 10;

  @media (max-width: 480px) {
    grid-column: 3 / 5;
    grid-row: 10 / 11;
  }
`
/* new row */
const img16 = css`
  grid-column: 1 / 2;
  grid-row: 10 / 11;

  @media (max-width: 480px) {
    grid-column: 1 / 3;
    grid-row: 12 / 13;
  }
`
const img17 = css`
  grid-column: 2 / 3;
  grid-row: 10 / 11;

  @media (max-width: 480px) {
    grid-column: 3 / 5;
    grid-row: 12 / 13;
  }
`
const img18 = css`
  grid-column: 3 / 5;
  grid-row: 10 / 11;
  padding-top: calc(3 / 4 * 100%) !important;

  @media (max-width: 480px) {
    grid-column: 1 / 2;
    grid-row: 6 / 7;
  }
`
/* new row */
const img19 = css`
  grid-column: 1 / 2;
  grid-row: 11 / 12;

  @media (max-width: 480px) {
    grid-column: 1 / 3;
    grid-row: 14 / 15;
  }
`
const img20 = css`
  grid-column: 2 / 3;
  grid-row: 11 / 12;

  @media (max-width: 480px) {
    grid-column: 3 / 5;
    grid-row: 14 / 15;
  }
`
const img21 = css`
  grid-column: 3 / 5;
  grid-row: 11 / 12;
  padding-top: calc(3 / 4 * 100%) !important;

  @media (max-width: 480px) {
    grid-column: 1 / 5;
    grid-row: 13 / 14;
  }
`

const SpecialRow = styled('div')`
  grid-column: 1 / 5;
  grid-row: 5 / 6;
  display: flex;
  padding-top: 0 !important;

  @media (max-width: 480px) {
    flex-wrap: wrap;
  }

  > div {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;

    @media (max-width: 480px) {
      flex: 0 0 50%;
      max-width: 50%;

      &:first-child {
        flex: 0 0 100%;
        max-width: 100%;
        order: 2;
      }
    }
  }
`

const Text = styled('div')`
  background-color: #dbdbdb;
  padding-top: 0 !important;
  > span {
    font-family: 'Adieu';
    color: red;
    text-transform: uppercase;
    display: inline-block;
    margin: 36px 60px 32px 24px;
    font-size: 36px;

    @media (max-width: 480px) {
      margin: 18px 24px 24px 12px;
      font-size: 24px;
    }
  }
`

// eslint-disable-next-line no-unused-vars
const getSize = (i, bp = 'desktop') => {
  switch (`${i}-${bp}`) {
    case '1-mobile':
      return 100
    case '1-desktop':
      return 50
    case '2-mobile':
      return 50
    case '2-desktop':
      return 25
    case '3-mobile':
      return 50
    case '3-desktop':
      return 25
    case '4-mobile':
      return 50
    case '4-desktop':
      return 25
    case '5-mobile':
      return 50
    case '5-desktop':
      return 25
    /* case '6-mobile':
      return 100
    case '6-desktop':
      return 100 */
    case '7-mobile':
      return 50
    case '7-desktop':
      return 33.3
    case '8-mobile':
      return 50
    case '8-desktop':
      return 33.3
    case '9-mobile':
      return 50
    case '9-desktop':
      return 25
    case '10-mobile':
      return 50
    case '10-desktop':
      return 25
    case '11-mobile':
      return 50
    case '11-desktop':
      return 25
    case '12-mobile':
      return 50
    case '12-desktop':
      return 25
    case '13-mobile':
      return 100
    case '13-desktop':
      return 50
    case '14-mobile':
      return 50
    case '14-desktop':
      return 50
    case '15-mobile':
      return 50
    case '15-desktop':
      return 50
    case '16-mobile':
      return 50
    case '16-desktop':
      return 25
    case '17-mobile':
      return 50
    case '17-desktop':
      return 25
    /* case '18-mobile':
      return 100
    case '18-desktop':
      return 100 */
    case '19-mobile':
      return 50
    case '19-desktop':
      return 25
    case '20-mobile':
      return 50
    case '20-desktop':
      return 25
    case '21-mobile':
      return 100
    case '21-desktop':
      return 50
    default:
      return 100
  }
}

/* eslint-disable no-eval */
export default props => (
  <React.Fragment>
    <GridContainer>
      {range(21).filter(d => ![ 6, 7, 8, 18 ].includes(d)).map(i => (
        <div
          css={eval(`img${pad(i)}`)}
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/grid/${pad(i)}.jpg)`
          }}
        />
      ))}
      <SpecialRow>
        <Text>
          <span>
            Tools to improve the working conditions of freelancers in semipublic
            spaces.
          </span>
        </Text>
        <div
          css={css`
            padding-top: calc(4 / 3 / 3 * 100%) !important;
            background-image: url(${process.env.PUBLIC_URL}/grid/07.jpg);

            @media (max-width: 480px) {
              padding-top: calc(4 / 3 / 2 * 100%) !important;
            }
          `}
        />
        <div
          css={css`
            padding-top: calc(4 / 3 / 3 * 100%) !important;
            background-image: url(${process.env.PUBLIC_URL}/grid/08.jpg);

            @media (max-width: 480px) {
              padding-top: calc(4 / 3 / 2 * 100%) !important;
            }
          `}
        />
      </SpecialRow>
      <Text
        css={css`
          grid-column: 3 / 5;
          grid-row: 10 / 11;
          display: flex;
          align-items: flex-end;

          @media (max-width: 480px) {
            grid-column: 1 / 5;
            grid-row: 11 / 12;
          }
        `}
      >
        <span>Yesterday writer, today designer, tomorrow programmer.</span>
      </Text>
      <Divider css={first}>
        {range(20).map((_, i) => <span>New Office</span>)}
      </Divider>
      <Divider css={second}>
        {range(20).map((_, i) => <span>New Office</span>)}
      </Divider>
    </GridContainer>
    <BackToTop
      onClick={() => {
        window.scrollTo({
          behavior: 'smooth',
          top: 0
        })
      }}
    >
      Back to top
    </BackToTop>
  </React.Fragment>
)
