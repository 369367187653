export const FONTSIZE = '14px'
export const BORDERWIDTH = 1
export const BORDER = `${BORDERWIDTH}px solid black`
export const LIGHTBLUE = '#E1EAF2'
export const BLUE = '#B2D5F8'
export const DARKBLUE = '#A2C6EB'
export const GREY = '#CBCBCB'
export const GREEN = '#8BED87'

export const range = n => {
  let result = []
  for (var i = 1; i <= n; i++) {
    result.push(i)
  }
  return result
}

export const pad = i => `${i < 10 ? 0 : ''}${i}`

export const getRelated = productId => {
  switch (productId) {
    case 'shirt':
      return [ 'bg1', 'bg2' ]
    case 'bg1':
      return [ 'shirt' ]
    case 'bg2':
      return [ 'shirt' ]
    case 'bg3':
      return [ 'shirt' ]
    case 'vest':
      return [ 'scarf', 'cap', 'barrier' ]
    case 'scarf':
      return [ 'vest', 'cap', 'barrier' ]
    case 'cap':
      return [ 'patches', 'barrier' ]
    case 'barrier':
      return [ 'vest', 'cap' ]
    case 'patches':
      return [ 'cap', 'barrier' ]
    default:
      return [ 'shirt', 'barrier', 'cap' ]
  }
}
