import React from 'react'

import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import emotionNormalize from 'emotion-normalize'
import { HashRouter as Router, Route, Link } from 'react-router-dom'

import ScrollToTop from './ScrollToTop.js'
import Home from './Home.js'
import Collective from './Collective.js'
import About from './About.js'
import Shop from './Shop.js'
import Cart from './Cart.js'
import Product from './Product.js'

import products from './products.json'
import { BORDER } from './utils'

import adieueot from './fonts/Adieu-Regular.eot'
import adieuwoff2 from './fonts/Adieu-Regular.woff2'
import adieuwoff from './fonts/Adieu-Regular.woff'
import adieuttf from './fonts/Adieu-Regular.ttf'
import monumenteot from './fonts/200de575dd703ae072159eb55b2ffcdc.eot'
import monumentwoff2 from './fonts/200de575dd703ae072159eb55b2ffcdc.woff2'
import monumentwoff from './fonts/200de575dd703ae072159eb55b2ffcdc.woff'
import monumentttf from './fonts/200de575dd703ae072159eb55b2ffcdc.ttf'

const Container = styled('div')`
  padding-top: 55px;
  @media (max-width: 480px) {
    padding-top: 74px;
  }
`

const Navigation = styled('ul')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0;
  list-style-type: none;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: white;
  border-bottom: ${BORDER};
  padding: 15px 28px 12px 6px;
  z-index: 10;

  @media (max-width: 480px) {
    flex-wrap: wrap;
    padding: 6px 12px;
    box-sizing: border-box;
  }

  li {
    flex-grow: 0;
    color: red;
    font-family: 'Adieu';
    margin: 8px 24px 0px 6px;
    font-size: 16px;

    @media (max-width: 480px) {
      &:last-of-type {
        flex-grow: 1;
        text-align: right;
        margin-right: 0;
      }
    }
    @media (min-width: 481px) {
      margin: 0 20px 0 12px;
      font-size: 18px;
    }

    &:first-of-type {
      flex: 100%;
      @media (min-width: 481px) {
        flex-grow: 1;
      }
    }
  }
`

export const CartContext = React.createContext({
  cartItems: [],
  addToCart: () => {},
  removeFromCart: () => {}
})

class App extends React.PureComponent {
  addToCart = product => {
    return new Promise(resolve => {
      window.setTimeout(() => {
        this.setState(
          prev => ({
            cartItems: [...prev.cartItems, product]
          }),
          () => {
            resolve(this.state.cartItems)
          }
        )
      }, 500)
    })
  }

  removeFromCart = product => {
    console.log(`remove ${product}`)
    return new Promise(resolve => {
      window.setTimeout(() => {
        this.setState(
          prev => ({
            cartItems: prev.cartItems.filter(d => d !== product)
          }),
          () => {
            resolve(this.state.cartItems)
          }
        )
      }, 250)
    })
  }

  state = {
    cartItems: [],
    addToCart: this.addToCart,
    removeFromCart: this.removeFromCart
  }

  render () {
    return (
      <Container>
        <Global
          styles={css`
            ${emotionNormalize}
            html, body {
              font-family: 'Monument', 'Helvetica Neue', Helvetica;
              -webkit-font-smoothing: antialiased;
              font-smoothing: antialiased;
              text-rendering: geometricPrecision;
              -moz-osx-font-smoothing: grayscale;
            }
            a {
              color: inherit !important;
              text-decoration: none !important;
            }
            a[href^=mailto]:hover {
              opacity: 0.7;
            }
            * {
              font-weight: normal;
            }
            @font-face {
              font-family: 'Adieu';
              src: url('${adieueot}');
              src: url('${adieueot}?#iefix') format('embedded-opentype'),
                url('${adieuwoff2}') format('woff2'),
                url('${adieuwoff}') format('woff'),
                url('${adieuttf}') format('truetype');
              font-weight: normal;
              font-style: normal;
            }

            @font-face {
              font-family: 'Monument';
              src: url('${monumenteot}');
              src: url('${monumenteot}?#iefix')
                  format('embedded-opentype'),
                url('${monumentwoff2}') format('woff2'),
                url('${monumentwoff}') format('woff'),
                url('${monumentttf}') format('truetype');
              font-weight: normal;
              font-style: normal;
            }
          `}
        />
        <CartContext.Provider value={this.state}>
          <Router>
            <Navigation>
              <li>
                <Link to='/'>NEW OFFICE</Link>
              </li>
              <li>
                <Link to='/collective'>Collective</Link>
              </li>
              <li>
                <Link to='/about'>About</Link>
              </li>
              <li>
                <Link to='/shop'>Shop</Link>
              </li>
              <li>
                <Link to='/cart'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={19 * 1.2}
                    height={21 * 1.2}
                    viewBox='0 0 19 21'
                  >
                    <g
                      fill='none'
                      fillRule='evenodd'
                      transform='translate(1 1.5)'
                    >
                      <ellipse
                        cx='5.5'
                        cy='6'
                        stroke='red'
                        strokeWidth='1.5'
                        rx='3.5'
                        ry='6'
                      />
                      <path
                        fill='#FFFFFF'
                        fillRule='nonzero'
                        stroke='red'
                        strokeWidth='1.5'
                        d='M0 5h11v13H0z'
                      />
                      {this.state.cartItems.length > 0 && (
                        <React.Fragment>
                          <circle
                            cx='11.5'
                            cy='7'
                            r='6.5'
                            fill='red'
                            fillRule='nonzero'
                          />
                          <text
                            fill='#FFF'
                            fontFamily='Monument'
                            fontSize='10'
                            textAnchor='middle'
                          >
                            <tspan x='11.5' y='10.5'>
                              {this.state.cartItems.length}
                            </tspan>
                          </text>
                        </React.Fragment>
                      )}
                    </g>
                  </svg>
                </Link>
              </li>
            </Navigation>
            <ScrollToTop />
            <Route exact path='/' render={() => <Home />} />
            <Route path='/collective' render={() => <Collective />} />
            <Route path='/about' render={() => <About />} />
            <Route path='/shop' render={() => <Shop products={products} />} />
            <Route
              path='/product/:id'
              render={({ match }) => <Product match={match} />}
            />
            <Route path='/cart' render={() => <Cart />} />
          </Router>
        </CartContext.Provider>
      </Container>
    )
  }
}

export default App
