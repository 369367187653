import React from 'react'
import { CartContext } from './App'
import styled from '@emotion/styled'
import Slider from './Slider'
import { Link } from 'react-router-dom'
import products from './products.json'
import {
  FONTSIZE,
  BORDER,
  BLUE,
  LIGHTBLUE,
  DARKBLUE,
  GREY,
  GREEN,
  getRelated,
  range
} from './utils'
import { ProductImage, ProductText, BackToTop } from './Shop'

const Container = styled('div')`
  display: flex;
  flex-direction: column;

  @media (min-width: 481px) {
    flex-direction: row;
  }
`

const ImageGallery = styled('div')`
  flex-basis: 66%;
  flex-shrink: 1;
  border: ${BORDER};
  border-left-width: 0;
  text-align: center;
  position: relative;
  height: 360px;

  @media (min-width: 480px) {
    max-width: 66%;
    height: 600px;
  }
  @media (max-width: 480px) {
    border-right-width: 0;
    border-bottom-width: 0;
  }
`

const ProductInfo = styled('div')`
  flex-basis: 34%;
  flex-shrink: 0;
  border: ${BORDER};
  border-left-width: 0;
  border-right-width: 0;

  @media (min-width: 481px) {
    padding-top: 32px;
  }
`

const Title = styled('h2')`
  margin: 16px;
  font-size: ${FONTSIZE};
  text-align: center;
  text-transform: uppercase;
`

const Description = styled('p')`
  font-size: ${FONTSIZE};
  line-height: 1.2;
  padding: 0 16px;
  @media (min-width: 1024px) {
    padding: 0 32px;
  }
`

const Price = styled('p')`
  text-transform: uppercase;
  padding: 0 16px 16px;
  @media (min-width: 1024px) {
    padding: 0 32px 32px;
  }
`

const AddToCartButton = styled('button')`
  border: ${BORDER};
  border-left-width: 0;
  text-transform: uppercase;
  background-color: ${BLUE};
  font-size: ${FONTSIZE};
  width: 100%;
  appearance: none;
  user-select: none;
  text-align: center;
  padding: 16px;
  cursor: pointer;
  &:disabled {
    color: inherit;
    cursor: default;
  }
  transition: background-color 100ms ease-in-out;
  ${({ initial }) =>
    initial &&
    `
    background-color: ${BLUE};
    &:hover {
      background-color: ${DARKBLUE};
    }
  `}
  ${({ waiting }) => waiting && `background-color: ${LIGHTBLUE};`}
  ${({ done }) => done && `background-color: ${GREEN};`}

  @media (max-width: 480px) {
    border-right-width: 0;
  }
`

const PropertiesList = styled('ul')`
  margin: 0;
  list-style-type: none;
  padding: 0 16px 16px;
  @media (min-width: 1024px) {
    padding: 0 32px 32px;
  }

  li {
    position: relative;
    padding-left: 16px;
    line-height: 1.2;
  }
  li:before {
    content: '+ ';
    position: absolute;
    left: 0;
    z-index: 1;
  }
`

const FitsWellWith = styled('h4')`
  background-color: ${GREY};
  text-align: center;
  padding: 16px;
  font-size: ${FONTSIZE};
  margin: 0;
  text-transform: uppercase;
  @media (min-width: 481px) {
    border-bottom: ${BORDER};
  }
`

const RelatedContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-bottom: ${BORDER};
`

const RelatedItem = styled('div')`
  flex: 20% 0;
  border-right: ${BORDER};

  @media (max-width: 1024px) {
    flex: 33.3%;
  }
  @media (max-width: 720px) {
    flex: 50%;
  }
  @media (max-width: 480px) {
    flex: 100%;
    border-right-width: 0;
    border-top: ${BORDER};
  }
`

export default ({ match }) => {
  const { cartItems, addToCart } = React.useContext(CartContext)
  const [status, setStatus] = React.useState('initial')
  const product = products.find(d => d.id === match.params.id)
  React.useEffect(
    () => {
      setStatus(cartItems.includes(product.id) ? 'done' : 'initial')
    },
    [product.id, cartItems]
  )
  return (
    <React.Fragment>
      <Container key={`product-container-${product.id}`}>
        <ImageGallery>
          <Slider>
            {range(product.photos).map(i => (
              <img
                key={`image-${i}`}
                src={`${process.env.PUBLIC_URL}/images/${product.id}/0${i}.jpg`}
                alt=''
              />
            ))}
          </Slider>
        </ImageGallery>
        <ProductInfo>
          <Title>{product.name}</Title>
          <Description>{product.description}</Description>
          <Price>EUR {product.price}</Price>
          <AddToCartButton
            key={`button-${product.id}`}
            disabled={cartItems.includes(product.id) || status === 'waiting'}
            onClick={() => {
              setStatus('waiting')
              addToCart(product.id).then(cartItems => {
                setStatus('done')
              })
            }}
            initial={status === 'initial'}
            waiting={status === 'waiting'}
            done={status === 'done'}
          >
            {(() => {
              switch (status) {
                case 'initial':
                  return 'Add to cart'
                case 'waiting':
                  return '...'
                case 'done':
                  return 'Successfully added to cart'
                default:
                  return 'Add to cart'
              }
            })()}
          </AddToCartButton>
          <Title>Product</Title>
          <PropertiesList>
            {product.properties.map((property, i) => (
              <li key={`prop-${i}`}>{property}</li>
            ))}
          </PropertiesList>
        </ProductInfo>
      </Container>
      <FitsWellWith>Fits well with</FitsWellWith>
      <RelatedContainer>
        {getRelated(product.id)
          .map(id => products.find(d => d.id === id))
          .map(product => (
            <RelatedItem key={`related-${product.id}`}>
              <Link to={`/product/${product.id}`}>
                <ProductImage
                  src={`${process.env.PUBLIC_URL}/images/home/${
                    product.id
                  }_01.jpg`}
                />
                <ProductText>{product.name}</ProductText>
              </Link>
            </RelatedItem>
          ))}
      </RelatedContainer>
      <BackToTop
        onClick={() => {
          window.scrollTo({
            behavior: 'smooth',
            top: 0
          })
        }}
      >
        Back to top
      </BackToTop>
    </React.Fragment>
  )
}
